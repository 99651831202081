import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text } from 'hudl-uniform-ui-components';
import { ItemTitle } from 'hudl-rn-uniform-ui';

const snippet = require('raw-loader!../../../../../data/snippets/rn-item-title.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, ItemTitle: ItemTitle }}
        code={snippet}
        platform="react-native"
        gitHubLink="type/item-title"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="level" types={['oneOf', 'string']}>
            <Text>The level (size) of the item title.</Text>
            <List type="unordered">
              <li>
                <code>default</code> (default)
              </li>
              <li>
                <code>small</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="alignment" types={['oneOf', 'string']}>
            <Text>The alignment of the item title.</Text>
            <List type="unordered">
              <li>
                <code>left</code> (default)
              </li>
              <li>
                <code>right</code>
              </li>
              <li>
                <code>center</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="isCaps" types={['bool']}>
            <Text>Determines whether to show the item title in caps.</Text>
          </PropListItem>

          <PropListItem name="space" types={['oneOf', 'string']}>
            <Text>
              Any of the{' '}
              <Link href="/resources/code-variables/space?reactnative">
                space variables
              </Link>
              .
            </Text>
          </PropListItem>

          <PropListItem name="theme" types={['oneOf', 'string']}>
            <Text>The theme to apply to the text.</Text>
            <List type="unordered">
              <li>
                <code>light</code> (default)
              </li>
              <li>
                <code>dark</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="testID" types={['string']}>
            <Text>
              A unique string to control this item title in automated tests.
            </Text>
          </PropListItem>

          <PropListItem name="accessibilityLabel" types={['string']}>
            <Text>
              Overrides the text that's read by the screen reader when the user interacts with the item title.
            </Text>
          </PropListItem>
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default ReactNativeTab;
